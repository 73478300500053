import React, { useState } from 'react';
import MqttService from '../../services/mqtt/mqttService';
import Schedules from './Schedules';

const SchedulesPage: React.FC<{ mqttService: MqttService }> = ({ mqttService }) => {
  const [activeTab, setActiveTab] = useState<string>('A');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="tabs">
        <button onClick={() => handleTabClick('A')} className={activeTab === 'A' ? 'active' : ''}>
          A
        </button>
        <button onClick={() => handleTabClick('B')} className={activeTab === 'B' ? 'active' : ''}>
          B
        </button>
        <button onClick={() => handleTabClick('T')} className={activeTab === 'T' ? 'active' : ''}>
          T
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'A' && <Schedules mqttService={mqttService} channel={0} />}
        {activeTab === 'B' && <Schedules mqttService={mqttService} channel={1} />}
        {activeTab === 'T' && <Schedules mqttService={mqttService} channel={2} />}
      </div>
    </div>
  );
};

export default SchedulesPage;
