import React, { useEffect } from 'react';
import MqttService from './mqttService';

import { useDispatch } from "react-redux";
import { setMqttState } from "../../redux/features/mqttStateSlice";

interface MqttProps {
  mqttService: MqttService;
}

interface mqtt_message
{
  id: string;
  topic : string;
  value : string;
};

// var client: mqtt.Client;


//let devNum = 0;

// const dispatch = useDispatch();


const Mqtt = ({ mqttService }: MqttProps) => {

  console.log('Mqtt component mounted or updated', mqttService);
  const dispatch = useDispatch();

  useEffect(() => {
    const client = mqttService.getClient();
  
// var mqtt_msg : mqtt_message[];


  // if (!client){
  //   client = mqtt.connect(options); 
  // }
  

    const SetPayload = (payload: mqtt_message) => {
      // console.log(payload.topic);
      if (payload.id !== "" && payload.topic !== "") {
        //console.log("SET MQTT");
        dispatch(setMqttState({ id: payload.id,  topic: payload.topic, value : payload.value }));
      }
    };


    // const subscribe = () => {
    //   let str = localStorage.getItem('devices');
    //   if (str && subscribed == 0)
    //   {
    //     let devices = JSON.parse(str);
      
    //     let items = devices.forEach((device : string) => {

    //       for(let i = 0; i < topics.length; i++){
    //         let topic = device + topics[i];
    //         client.subscribe(topic, {qos: 1}, (error) => {
    //           if (error) {
    //             console.log('Subscribe to topics error', error)
    //             return
    //           }
    //           console.log('Subscribe to topic ' + topic);
    //           // setIsSub(true)
    //         });
    //       }
    //       // devNum = devNum + 1;
    //     });
    //     subscribed = 1;
    //   }
    // }
      const handleConnect = () => {
        // client.setMaxListeners(0);  //TODO
        console.log("Connected");
        // setTimeout(() => {
          mqttService.subscribe();
        // }, 10000); // Измените значение задержки по необходимости
      };

      const handleReconnect = () => {
        console.log("Reconnect");
        // setTimeout(() => {
          mqttService.subscribe();
        // }, 10000); // Измените значение задержки по необходимости
      };

      const handleError = (err: Error) => {
        console.error('Connection error: ', err);
        // client.end();
      };

      client.on('connect', handleConnect);
      // Подписываемся на событие reconnect
      client.on('reconnect', handleReconnect);
      client.on('error', handleError);

      // Если клиент уже подключен, вызываем handleConnect сразу
      if (client.connected) {
        handleConnect();
      }

      client.on('message', (topic, message) => {
        let _id = topic.split("/")[0];
        let _topic = topic;
        const payload = { id: _id, topic: _topic, value: message.toString() };
        SetPayload(payload);
        });
      // Отписываемся от MQTT при размонтировании компонента
      return () => {
        client.removeListener('connect', handleConnect); // Удаляем обработчик события connect
        client.removeListener('reconnect', handleReconnect);
        client.removeListener('error', handleError);
        mqttService.unsubscribe();
        // client.end();
      };
    }, [mqttService, dispatch]);
    // Компонент Mqtt не возвращает ничего, или возвращает null/JSX
    return null;
};
export default React.memo(Mqtt);