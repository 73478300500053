import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, Box, useMediaQuery  } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useDispatch, useSelector } from "react-redux";
import { setActiveDevState } from "../../redux/features/activeDevStateSlice";
import { RootState } from "../../redux/store";

import { CheckCircle, Cancel, Help } from "@mui/icons-material";

import "./style.css"

type Settings = {
  description: string;
  webPassw: string;
  masterRFID: string;
  timezone: string;
  timezoneloc: string;
  mqtt_broker_uri: string;
  irCarrierFreq: number;
  irCarrierDC: number;
  dateFormat: number;
  bcklightLCD: number;
  auxSensType: number;
  altRfidButCh: number;
  ble1: string;
  ble2: string;
  ble1Ch: number;
  ble2Ch: number;
};

interface TopbarProps {
  isFullScreen: boolean;
}

const Topbar: React.FC<TopbarProps> = ({ isFullScreen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let str = localStorage.getItem('devices');

  const [online, setOnline] = useState<boolean | undefined>(false);
  const [description, setDescription] = useState<string | undefined>("");

  const devices = useSelector((state: RootState) => state.mqttState);
  const activeDev = useSelector((state: RootState) => state.activeDevState);

  useEffect(() => {
    const deviceIndex = devices.mqttState.findIndex((device) => device.id === activeDev.activeDevState);
    if (deviceIndex !== -1){
      setOnline(devices.mqttState[deviceIndex].online);

      if (devices.mqttState[deviceIndex].config?.files?.settings){
        const settingsStr = devices.mqttState[deviceIndex].config?.files?.settings;
        
        if (settingsStr !== undefined){
          var settings: Settings | undefined = JSON.parse(settingsStr);

          if (settings !== undefined){
            setDescription(settings.description);
          }
        }
        else{
          setDescription(undefined);
        }
      }
      else{
        setDescription(undefined);
      }
    }
    else{
      setOnline(undefined);  
      setDescription(undefined);    
    }
  }, [devices, activeDev.activeDevState]);

  let items = "";
  if (str)
  {
    let devices = JSON.parse(str);
    var count = 0;
    items = devices.map((device : string) =>
      <Dropdown.Item key={count++} onClick={() => {
        dispatch(setActiveDevState(device));}}>
        {device}
      </Dropdown.Item>
    );
  }

  const getStatusLabel = () => {
    if (isMobile) {
      return "";
    }
    else{
      if (online === true) {
        return <span style={{ color: 'green' }}>online</span>;
      } else if (online === false) {
        return <span style={{ color: 'red' }}>offline</span>;
      } else {
        return <span style={{ color: 'gray' }}>unknown</span>;
      }
    }
  };
  

  return (
    <AppBar
      position="fixed"
      sx={{
        width:  isFullScreen ? `calc(100% - ${sizeConfigs.sidebar_collapse.width})` : `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,
        height: sizeConfigs.topbar.height,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        textAlign: 'left'      
      }}
    >
      <Toolbar>
          <Box display="flex" alignItems="center" width="100%">
            <DropdownButton
              variant="secondary"
              id="dropdown-secondary-button"
              title={isMobile ? "Device" : "Choose device"}
            >
              {items}
            </DropdownButton>
            <Box
              ml={2}
              display="flex"
              alignItems="center"
              flex="1"
              minWidth="0"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {isMobile ? 
                (description !== undefined ? description : activeDev.activeDevState) : 
                `Current device: ${activeDev.activeDevState + (description !== undefined ? " (" + description + ")" : "")}`
              }
            </Box>
            <Box ml={2} display="flex" alignItems="center">
              {online !== undefined && (
                <>
                  {online ? (
                    <CheckCircle sx={{ color: 'green', fontSize: 20, mr: 1 }} />
                  ) : (
                    <Cancel sx={{ color: 'red', fontSize: 20, mr: 1 }} />
                  )}
                  {getStatusLabel()}
                </>
              )}
              {online === undefined && (
                <>
                  <Help sx={{ color: 'gray', fontSize: 20, mr: 1 }} />
                  {getStatusLabel()}
                </>
              )}
            </Box>
          </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;