import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";

type MqttMsg = {
  id: string;
  topic: string;
  value: string;
}

type tempType = {
  main: string;
  aux: string;
}

type thermType = {
  state: string;
  stat: string;
  mode: string;
  action: string;
}

type timType = {
  state: string;
  stat: string;
  mode: string;
  action: string
}

type StateType = {
    date?: string;
    temp?: tempType;
    humid?: string;
    T?: thermType;
    A?: timType;
    B?: timType;
}

type SysType = {
  rst?: string;
  ver?: string;
  ota: number;
}

type FilesType = {
  settings?: string;
  progT?: string;
  progAB?: string;
  sch?: string;
  log?: string;
}

type ConfigType = {
  sys?: SysType;
  state?: StateType;
  files?: FilesType;
}

type Device = {
    id: string;
    online: boolean;
    sync: boolean;
    config: ConfigType;
}


export type mqttState = {
  mqttState: Device[];
};

const initialState: mqttState = {
  mqttState: []
};

// Вспомогательная функция для обновления состояния устройства
const updateDeviceState = (device: Device, topic: string, value: string): Device => {
  return produce(device, (draft) => {
    const subTopics = topic.split('/').slice(1);
    let currentState: any = draft.config;

    subTopics.reduce((acc, subTopic, index) => {
      if (index === subTopics.length - 1) {
        acc[subTopic as keyof StateType] = value;
      } else {
        acc[subTopic] = acc[subTopic] || {};
        return acc[subTopic];
      }
      return acc[subTopic];
    }, currentState);
  });
};

// Вспомогательная функция для создания вложенного состояния
const createNestedState = (topic: string, value: string): ConfigType => {
  const subTopics = topic.split('/').slice(1);
  let currentState: any = {};

  subTopics.reduce((acc, subTopic, index) => {
    if (index === subTopics.length - 1) {
      acc[subTopic as keyof ConfigType] = value;
    } else {
      acc[subTopic] = acc[subTopic] || {};
      return acc[subTopic];
    }
    return acc[subTopic];
  }, currentState);

  return currentState;
};


export const mqttStateSlice = createSlice({
  name: "mqttState",
  initialState,
  reducers: {
    setMqttState: (state, action: PayloadAction<MqttMsg>) => {
      const { id, topic, value } = action.payload;
      const deviceIndex = state.mqttState.findIndex((device) => device.id === id);

      if (deviceIndex !== -1){
        if (topic.endsWith("/ping")) {
          state.mqttState[deviceIndex].online = true;
        } else if (topic.endsWith("/offline")) {
          state.mqttState[deviceIndex].online = false;
        } else if (topic.endsWith("/sync")) {
            state.mqttState[deviceIndex].sync = true;
        }
        else
        {
          if (!(topic.endsWith("/ver") || topic.endsWith("/rst") || topic.endsWith("/date"))){
            state.mqttState[deviceIndex].online = true;
          }
          // Устройство найдено, обновление его состояния
          const device = state.mqttState[deviceIndex];
          const updatedDevice = updateDeviceState(device, topic, value);

          // Обновление массива mqttState с обновленным устройством
          state.mqttState[deviceIndex] = updatedDevice;
        }
      } else {
        // Устройство не найдено, добавление нового устройства в массив
        const newDevice: Device = {
          id: id,
          online: topic.endsWith("/offline") ? false : 
          ((topic.endsWith("/ver") || topic.endsWith("/rst") || topic.endsWith("/date")) ? false : true ),
          sync: topic.endsWith("/sync") ? true : false,
          config: createNestedState(topic, value),
        };

        state.mqttState.push(newDevice);
      }

      // console.log(state.mqttState);

      //const device = state.mqttState.find(d => action.payload.id === d.id);
      //const devices = state.mqttState.filter(b => action.bird !== b.name);
      //state.mqttState = state.mqttState.filter((dev) => dev.id !== action.payload.id);
      
      // console.log("STORE");
      // console.log(action.payload.id + "  " + action.payload.text);
    }
  }
});

export const {
  setMqttState
} = mqttStateSlice.actions;

export default mqttStateSlice.reducer;