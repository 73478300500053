import { configureStore } from "@reduxjs/toolkit";

import appStateSlice from "./features/appStateSlice";
import mqttStateSlice from "./features/mqttStateSlice";
import activeDevStateSlice from "./features/activeDevStateSlice";

export const store = configureStore({
  reducer: {
    appState: appStateSlice,
    mqttState: mqttStateSlice,
    activeDevState: activeDevStateSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;