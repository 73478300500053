import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";

import React, { useState } from 'react';
import { IconButton } from '@mui/material';

import { Menu, MenuOpen } from "@mui/icons-material"; 

import "../../index.css";

const MainLayout = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Состояние для открытия/закрытия боковой панели


  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState); // используем функцию обновления состояния для предотвращения перекрытия событий
    // console.log("Sidebar state toggled");
  };

  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

      <Box
        sx={{
          width: sizeConfigs.sidebar_collapse.width,
          flexShrink: 0,
          backgroundColor: colorConfigs.sidebar.bg,
          position: "absolute",
          top: 0,
          left: isSidebarOpen ? 
          `calc(${sizeConfigs.sidebar.width} - ${sizeConfigs.sidebar_collapse.width})` : 0,
          height: sizeConfigs.topbar.height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1201,
        }}
      >
        <IconButton
          className="sidebar-toggle-button" 
          onClick={toggleSidebar}
          style={{
            position: "absolute",
            color: colorConfigs.topbar.bg // Устанавливаем цвет значка в зависимости от состояния isSidebarOpen
          }}
        >
          {isSidebarOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: isSidebarOpen ? `calc(100% - ${sizeConfigs.sidebar.width})` : `100%`, // изменяем ширину основного содержимого страницы в зависимости от состояния боковой панели
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
          overflow: "auto" // Позволяет содержимому прокручиваться
        }}
      >
      <Topbar  isFullScreen={!isSidebarOpen}/>
      <Toolbar />
      <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;