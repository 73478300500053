import React, { useState, useEffect, Fragment } from "react";

import { useSelector  } from "react-redux";
import { RootState } from "../../redux/store";
import MqttService from '../../services/mqtt/mqttService';

import { Box, Typography, Grid, FormControl, Select, MenuItem, Checkbox, FormControlLabel, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LightbulbTwoTone, Timelapse, Paid, RestartAlt, History } from '@mui/icons-material';

import colorConfigs from "../../configs/colorConfigs";

interface MonitorProps {
    mqttService: MqttService;
    channel: number;
  }

type TypeMode = {
  man: number;
  mode: number;
};

type TypeStat = {
  time: number;
  cost: number;
};
  
const Monitor: React.FC<MonitorProps> = ({ mqttService, channel }) => {
  
  const devices = useSelector((state: RootState) => state.mqttState);
  const activeDev = useSelector((state: RootState) => state.activeDevState);

  const [TMode, setTMode] = useState<TypeMode | undefined>();
  const [AMode, setAMode] = useState<TypeMode | undefined>();
  const [BMode, setBMode] = useState<TypeMode | undefined>();

  const [actionT, setActionT] = useState<string | undefined>();
  const [actionA, setActionA] = useState<string | undefined>();
  const [actionB, setActionB] = useState<string | undefined>();

  const [statT, setStatT] = useState<TypeStat | undefined>();
  const [statA, setStatA] = useState<TypeStat | undefined>();
  const [statB, setStatB] = useState<TypeStat | undefined>();

  const [stateT, setStateT] = useState<string | undefined>();
  const [stateA, setStateA] = useState<string | undefined>();
  const [stateB, setStateB] = useState<string | undefined>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getChannelStateIcon = (state: string | undefined) => {
    if(state !== undefined){
      if (state === "0") {
        return <LightbulbTwoTone sx={{color: 'gray', backgroud: 'black'}} />;
      } else //if (state === "1")
        {
        return <LightbulbTwoTone sx={{color: 'yellow', backgroud: 'black'}} />;
      }
    }
     else {
      return null;
    }
  };

  // Функция для получения пиктограммы статистики канала
  const getChannelStatIcon = (stat : TypeStat | undefined) => {
    if(stat){
      return (
        <>
          <Timelapse sx={{ mr: 1 }} /> {stat.time.toFixed(2)}
          <br />
          <Paid sx={{ mr: 1 }} /> {stat.cost.toFixed(2)}
        </>
      );
    }
    else{
      return null;
    }
  };

  const _setMode = (man: boolean, val: string, ch: number) => {
    
    let topic : string;
    var message;
    
    if (man == false || val !== undefined){
      if (man == false){
        message = '{"man":0}';
      }
      else {
        message = `{"man":1, "mode":` + val + `}`;
      }
    
      switch (ch){
        case 0:
          topic = activeDev.activeDevState + '/desired/state/A/mode';
          break;
        case 1:
          topic = activeDev.activeDevState + '/desired/state/B/mode';
          break;          
        default:
          topic = activeDev.activeDevState + '/desired/state/T/mode';
          break;
      }
      const qos = 2;
      mqttService.publishMessage(topic, message, qos, false);
    }
  };

  useEffect(() => {
    const deviceIndex = devices.mqttState.findIndex((device) => device.id === activeDev.activeDevState);
    if (deviceIndex !== -1){
      const deviceConfig = devices.mqttState[deviceIndex].config.state;
      setActionT(deviceConfig?.T?.action);
      setActionA(deviceConfig?.A?.action);
      setActionB(deviceConfig?.B?.action);

      setStateT(deviceConfig?.T?.state);
      setStateA(deviceConfig?.A?.state);
      setStateB(deviceConfig?.B?.state);

      const parseMode = (mode: string | undefined): TypeMode | undefined => {
        if (mode) {
          return JSON.parse(mode);
        }
        return undefined;
      };

      setTMode(parseMode(deviceConfig?.T?.mode));
      setAMode(parseMode(deviceConfig?.A?.mode));
      setBMode(parseMode(deviceConfig?.B?.mode));

      const parseStat = (stat: string | undefined): TypeStat | undefined => {
        if (stat) {
          return JSON.parse(stat);
        }
        return undefined;
      };

      setStatT(parseStat(deviceConfig?.T?.stat));
      setStatA(parseStat(deviceConfig?.A?.stat));
      setStatB(parseStat(deviceConfig?.B?.stat));
    }
    else{
      setActionT(undefined);
      setActionA(undefined);
      setActionB(undefined);
      setStatT(undefined);
      setStatA(undefined);
      setStatB(undefined);
      setStateT(undefined);
      setStateA(undefined);
      setStateB(undefined);
      setTMode(undefined);
      setAMode(undefined);
      setBMode(undefined);
    }
  }, [devices, activeDev.activeDevState]);

  const renderChannel = (channel: number) => {
    let state: string | undefined, 
    mode: TypeMode | undefined, 
    stat: TypeStat | undefined, 
    action: string | undefined, 
    channelName: string;

    switch (channel) {
      case 2:
        state = stateT;
        mode = TMode;
        stat = statT;
        action = actionT;
        channelName = 'T';
        break;
      case 0:
        state = stateA;
        mode = AMode;
        stat = statA;
        action = actionA;
        channelName = 'A';
        break;
      case 1:
        state = stateB;
        mode = BMode;
        stat = statB;
        action = actionB;
        channelName = 'B';
        break;
      default:
        return null;
    }

    return (
      <Box sx={{ p: 2, bgcolor: colorConfigs.topbar.bg, boxShadow: 1, borderRadius: 4, margin: 1 }}>
        <Typography variant="h5" gutterBottom>
          {channel === 2 ? `Thermostat ("${channelName}" channel)` : `Timer ("${channelName}" channel)`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={isMobile ? 12 : 6}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td className="cell">State:</td>
                  <td className="cell">{getChannelStateIcon(state)}</td>
                </tr>
                <tr>
                  <td className="cell">Mode:</td>
                  <td className="cell">
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <Select
                        value={mode?.mode !== undefined && (channel === 2 || (mode.mode === 3 || mode.mode === 4)) ? "" + mode.mode : channel === 2 ? "0" : "3"}
                        onChange={(event) => _setMode(mode?.man && mode?.man === 1 ? true : false, event.target.value, channel)}
                        disabled={mode?.man !== 1}
                      >
                        {channel === 2 ? [
                          <MenuItem key="0" value="0">Eco</MenuItem>,
                          <MenuItem key="1" value="1">Comfort</MenuItem>,
                          <MenuItem key="2" value="2">Auto</MenuItem>
                        ] : null}
                        <MenuItem value="3">OFF</MenuItem>
                        <MenuItem value="4">ON</MenuItem>
                      </Select>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td className="cell">Manual control:</td>
                  <td className="cell">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mode?.man !== undefined && mode?.man === 1}
                          onChange={(event) => _setMode(event.target.checked, 
                              mode?.mode !== undefined && (channel === 2 || (mode.mode === 3 || mode.mode === 4)) ? "" + mode.mode : channel === 2 ? '0' : '3',
                              channel)}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12} sm={isMobile ? 12 : 6}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td className="cell">Channel statistics:</td>
                  <td className="cell">{getChannelStatIcon(stat)}</td>
                </tr>
                <tr>
                  <td className="cell"></td>
                  <td className="cell">
                    <button onClick={() => _setMode(true, '255', channel)}>Reset<RestartAlt /></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          <History/>: {action !== undefined ? action : '...'}
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      {renderChannel(channel)}
    </div>
  );
};

export default Monitor;