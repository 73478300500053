import React, { useState, useEffect  } from "react";

import { useSelector  } from "react-redux";
import { RootState } from "../../redux/store";

import MqttService from '../../services/mqtt/mqttService';
import Modal from "../../components/common/Modal";

import { FileDownload, RestartAlt, SystemUpdateAlt } from '@mui/icons-material';
import { styled } from "@mui/system";
import Button from "@mui/material/Button";

import "./style.css";

type LogType = {
  data: string;
  offset: number;
  len: number;
  total: number;
}

const SystemPage = ({ mqttService }: { mqttService: MqttService }) => {
  
  const devices = useSelector((state: RootState) => state.mqttState);
  const activeDev = useSelector((state: RootState) => state.activeDevState);

  var deviceIndex = devices.mqttState.findIndex(
    (device) => device.id === activeDev.activeDevState
  );

  const [logData, setLogData] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalRstOpen, setisModalRstOpen] = useState<boolean>(false);
  const [isModalUpdOpen, setisModalUpdOpen] = useState<boolean>(false);
  const [isModalOtaProgressOpen, setisModalOtaProgressOpen] = useState<boolean>(false);

  const [isUpdPrev, setisUpdPrev] = useState<boolean>(false);

  const [rst, setRst] = useState<any>();
  const [ver, setVer] = useState<any>();
  const [otaProgress, setOtaProgress] = useState<number>(0);

  const CHUNK_SIZE = 2048;

  const StyledButton = styled(Button)({
    margin: "8px",
  });

  const fetchLogChunk = (offset: number, length: number) => {
    if (deviceIndex !== -1) {
      setLoading(true);
      const topic = activeDev.activeDevState + '/desired/sync';
      const message = JSON.stringify({ mode: "log", offset, len: length });
      mqttService.publishMessage(topic, message, 2, false);
    }
  };

  const handleDownloadLog = () => {
    setLogData("");
    setOffset(0);
    fetchLogChunk(0, CHUNK_SIZE);
  };

  const handleReboot = () => {
    setisModalRstOpen(true);
  };

  const confirmReboot = () => {
    const topic = `${activeDev.activeDevState}/desired/sys/reboot`;
    mqttService.publishMessage(topic, JSON.stringify({}), 2, false);
    setisModalRstOpen(false);
  };

  const handleOtaUpdate = (prev: boolean) => {
    setisUpdPrev(prev);
    setisModalUpdOpen(true);
  };

  const confirmOtaUpdate = () => {
    const topic = `${activeDev.activeDevState}/desired/sys/otaStart`;
    const message = isUpdPrev === false ? "0" : "1";
    mqttService.publishMessage(topic, message, 2, false);
    setisModalUpdOpen(false);
  };

  const decodeBase64 = (base64: string): string => {
    try {
      return atob(base64);
    } catch (e) {
      console.error("Failed to decode base64:", e);
      return "";
    }
  };

  useEffect(() => {
    deviceIndex = devices.mqttState.findIndex((device) => device.id === activeDev.activeDevState);
    if (deviceIndex !== -1){
      setVer(devices.mqttState[deviceIndex].config.sys?.ver);
      setRst(devices.mqttState[deviceIndex].config.sys?.rst);
    }
    else{
      setVer(undefined);
      setRst(undefined);    
    }
  }, [devices, activeDev.activeDevState]);

  useEffect(() => {
    const deviceIndex = devices.mqttState.findIndex(
      (device) => device.id === activeDev.activeDevState
    );

    if (deviceIndex !== -1) {
      const message = devices.mqttState[deviceIndex].config?.files?.log;

      if (message)
      {
        // console.log(message);
        try {
          const cleanMessage = message.trim();
          const log: LogType = JSON.parse(cleanMessage);

          if (log.data) {
            const decodedData = decodeBase64(log.data);

            if (log.offset === offset) {
              // console.log("DATA");
              // console.log(decodedData);

              setLogData((prevData) => prevData + decodedData);
              setOffset((prevOffset) => prevOffset + log.len);
              setLoading(false);
              if (log.offset + log.len < log.total) {
                fetchLogChunk(log.offset + log.len, CHUNK_SIZE);
              }
            }
          }
        } catch (e) {
          console.error("Failed to parse log data:", e);
        }
      }
    }
  }, [devices.mqttState]);

  useEffect(() => {
    if (deviceIndex !== -1 && devices.mqttState[deviceIndex].config.sys?.ota) {
      const otaValue = devices.mqttState[deviceIndex].config.sys?.ota;
      if (otaValue && !isNaN(otaValue)) {
        if (otaProgress !== otaValue) {
          setOtaProgress(otaValue);
          setisModalOtaProgressOpen(true);
        }
      }
    }
  }, [devices.mqttState, deviceIndex]);


  return (
    <div className="log-container">
      <legend>System tools</legend>
      <div>
        <table className="table">
          <tbody>
            <tr>
              <td>FW version: </td>
              <td style={{ textAlign: "center" }}>{ver != undefined ? ver : "..."}</td>
              <td style={{ textAlign: "right" }}>
                <div className="button-container">
                  <button onClick={() => handleOtaUpdate(false)}><SystemUpdateAlt/></button>
                  <button onClick={() => handleOtaUpdate(true)}><SystemUpdateAlt/></button>
                </div>
              </td>
            </tr>
            <tr>
              <td>Reset reason: </td>
              <td style={{ textAlign: "center" }}>{rst != undefined ? rst : "..."}</td>
              <td style={{ textAlign: "right" }}>
                <div className="button-container">
                  <button onClick={handleReboot}><RestartAlt/></button>
                </div>
              </td>
            </tr>
            <tr>
              <td>System Log</td>
              <td style={{ textAlign: "center" }}>{loading && <p>Loading...</p>}</td>
              <td style={{ textAlign: "right" }}>
                <div className="button-container">
                  <button onClick={handleDownloadLog}><FileDownload/></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <textarea
        readOnly
        className="log-textarea"
        value={logData}
      />

      {isModalUpdOpen && (
        <Modal onClose={() => setisModalUpdOpen(false)}>
          <p>Are you sure you want to update your device with {isUpdPrev === false ? "last" : "previous"} version?</p>
          <StyledButton
              variant="contained"
              onClick={confirmOtaUpdate}
            >
              Yes
          </StyledButton>
          <StyledButton
              variant="contained"
              onClick={() => setisModalUpdOpen(false)}
            >
              No
          </StyledButton>
        </Modal>
      )}

      {isModalOtaProgressOpen && (
        <Modal onClose={() => setisModalOtaProgressOpen(false)}>
          <div>
            <p>OTA Progress: {otaProgress}%</p>
            <div className="progress-bar-container">
              <progress
                className="styled-progress-bar"
                value={otaProgress}
                max="100"
              >
                {otaProgress}%
              </progress>
            </div>
            <StyledButton
              variant="contained"
              onClick={() => setisModalOtaProgressOpen(false)}
            >
              OK
            </StyledButton>
          </div>
        </Modal>
      )}

      {isModalRstOpen && (
        <Modal onClose={() => setisModalRstOpen(false)}>
          <p>Are you sure you want to reboot the device?</p>
          <StyledButton
            variant="contained"
            onClick={confirmReboot}
          >
            Yes
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => setisModalRstOpen(false)}
          >
            No
          </StyledButton>
        </Modal>
      )}
    </div>
  );
};

export default SystemPage;