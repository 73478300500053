import React from 'react';
import { useNavigate } from "react-router-dom";

import { Logout } from '@mui/icons-material';

type Props = {};

const LogoutPage = (props: Props) => {

  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("devices");
    navigate("/");
    window.location.reload();
  }

  return (
    <div>
      <button className="add-button" onClick={handleClick}><Logout/></button>
    </div>
  );
};

export default LogoutPage;