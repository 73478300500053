import { useState } from 'react';

export interface IToken{
    token: string;
    devices: object;
}

export interface ISetToken{
  //setToken: React.Dispatch<React.SetStateAction<IToken | undefined>>;
  setToken: (userToken: IToken | undefined) => void
}

export default function useToken() {
  const getToken = () => {
    let userToken = localStorage.getItem('token');
    //let userToken = null;
    //if (tokenString) {
    //    userToken = JSON.parse(tokenString);
    //}
    return userToken
  };

  const [token, setToken] = useState(getToken());
  
  function saveToken (userToken: IToken | undefined){ 
  // const saveToken = (userToken: IToken) => {
    if (userToken){
      localStorage.setItem('token', userToken.token);
      localStorage.setItem('devices', JSON.stringify(userToken.devices));
      setToken(userToken.token);
    }
  };

  return {
    setToken: saveToken,
    token
  }
}