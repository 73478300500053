import React from 'react';

type Props = {};

const DashboardIndex = (props: Props) => {
  return (
    <div>DashboardIndex</div>
  );
};

export default DashboardIndex;