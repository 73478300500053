import React, { useState } from "react";
import "./style.css"

interface LegendProps {
    channel: number;
  }
  
const Legend: React.FC<LegendProps> = ({ channel }) => {

    const [legendVisible, setLegendVisible] = useState(false);

    const toggleLegend = () => {
        setLegendVisible(!legendVisible);
      };

  return (
    <div>
      {channel === 0 ?
          <div> 
            <button type="button" className="add-button" onClick={toggleLegend}>
              {legendVisible ? 'Press to hide thermostat modes legend' : 'Press to show thermostat modes legend'}
            </button>
            {legendVisible && (
              <table className="table program-container">
                <tbody>
                  <tr>
                    <td className="cell-eco">ECO</td>
                    <td>Keep economy temperature</td>
                  </tr>
                  <tr>
                    <td className="cell-comfort">COMFORT</td>
                    <td>Keep comfort temperature</td>
                  </tr>
                  <tr>
                    <td className="cell-auto">AUTO</td>
                    <td>Keep COMFORT if people detected, ECO or OFF otherwise</td>
                  </tr>
                  <tr>
                    <td className="cell-undefined">UNDEFINED</td>
                    <td>Undefined mode</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div> :
          <div> 
            <button type="button" className="add-button" onClick={toggleLegend}>
              {legendVisible ? 'Press to hide timer modes legend' : 'Press to show timer modes legend'}
            </button>
            {legendVisible && (
              <table className="table">
                <tbody>
                  <tr>
                    <td className="cell-off">OFF</td>
                    <td>Switch OFF timer channel</td>
                  </tr>
                  <tr>
                    <td className="cell-on">ON</td>
                    <td>Switch ON timer channel</td>
                  </tr>
                  <tr>
                    <td className="cell-undefined">UNDEFINED</td>
                    <td>Undefined mode</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        }
    </div>
  );
};

export default Legend;