import React, { useState } from 'react';
import MqttService from '../../services/mqtt/mqttService';
import Programs from './Programs';

const ProgramsPage: React.FC<{ mqttService: MqttService }> = ({ mqttService }) => {
  const [activeTab, setActiveTab] = useState<string>('thermostat');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="tabs">
        <button onClick={() => handleTabClick('thermostat')} className={activeTab === 'thermostat' ? 'active' : ''}>
          Thermostat
        </button>
        <button onClick={() => handleTabClick('timer')} className={activeTab === 'timer' ? 'active' : ''}>
          Timer
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'thermostat' && <Programs mqttService={mqttService} channel={0} />}
        {activeTab === 'timer' && <Programs mqttService={mqttService} channel={1} />}
      </div>
    </div>
  );
};

export default ProgramsPage;
