import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import MqttService from "../../services/mqtt/mqttService";
import Modal from "../../components/common/Modal";

import colorConfigs from "../../configs/colorConfigs";

import { styled } from "@mui/system";
import Button from "@mui/material/Button";

import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import "./style.css"

import Legend from "./Legend"


interface Schedule {
  program: number;
  channel: number;
  day: number;
}

interface Interval {
  start: string;
  end: string;
  mode: number;
}

interface Program {
  program: number;
  value: Interval[];
}

interface IMode {
  [key: number]: string
}

var modes: IMode = {
  0: "ECO",
  1: "COMFORT",
  2: "AUTO",
  3: "OFF",
  4: "ON",
  6: "UNDEFINED",
  255: "RESET_STAT"
};

var _day: IMode = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Every day"
};

var colorMode: IMode = {
  0: "green",
  1: "red",
  2: "blue", 
  3: "black",
  4: "white",
  6: "yellow"
};

var ch: IMode = {
  0: "A",
  1: "B",
  2: "T"
};


const StyledButton = styled(Button)({
  margin: "8px",
});

interface SchedulesProps {
  mqttService: MqttService;
  channel: number;
}

const Schedules: React.FC<SchedulesProps> = ({ mqttService, channel }) => {

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const activeDev = useSelector((state: RootState) => state.activeDevState);
  const devices = useSelector((state: RootState) => state.mqttState);
  var deviceIndex = devices.mqttState.findIndex(
    (device) => device.id === activeDev.activeDevState
  );
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedProgramIndex, setSelectedProgramIndex] = useState<number>(-1);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [everyday, setEveryday] = useState<boolean>(false);

  useEffect(() => {
    deviceIndex = devices.mqttState.findIndex((device) => device.id === activeDev.activeDevState);
    if (deviceIndex !== -1) {
      if (devices.mqttState[deviceIndex].config?.files?.sch !== undefined) {
        const storedSchedules = JSON.parse(
          devices.mqttState[deviceIndex].config?.files?.sch as string
        );
        setSchedules(storedSchedules);
        setEveryday(storedSchedules.some((schedule: Schedule) => schedule.day === 7));
      }
      else{
        setSchedules([]);
      }
      if (channel === 2) {
        if (devices.mqttState[deviceIndex].config?.files?.progT !== undefined) {
          const storedPrograms = JSON.parse(
            devices.mqttState[deviceIndex].config?.files?.progT as string
          );
          setPrograms(storedPrograms);
        }
        else{
          setPrograms([]);
        }
      } else {
        if (devices.mqttState[deviceIndex].config?.files?.progAB !== undefined) {
          const storedPrograms = JSON.parse(
            devices.mqttState[deviceIndex].config?.files?.progAB as string
          );
          setPrograms(storedPrograms);
        }
        else{
          setPrograms([]);
        }
      }
    }
    else{
      setSchedules([]);
      setPrograms([]);
    }
  }, [activeDev.activeDevState/*, devices*/]);

  const handleProgramChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProgramIndex(parseInt(event.target.value));
  };

  const handleSaveClick = () => {
    if (deviceIndex !== -1) {
      const topic = activeDev.activeDevState + '/desired/files/sch';
      const message = JSON.stringify(schedules);

      console.log(message);

      const qos = 2;
      mqttService.publishMessage(topic, message, qos, false);
    }
  };

  // const toggleModal = () => {
  //   setShowModal(!showModal);
  // };

  const handleDayClick = (day: number) => {
    setSelectedDay(day);

    const schedule = schedules.find(schedule => schedule.day === day && schedule.channel === channel);
    const program = schedule ? programs.find(p => p.program === schedule.program) : null;

    if (program && program.program !== undefined)
    {
      setSelectedProgramIndex(program.program);
    }
    else
    {
      setSelectedProgramIndex(0);
    }

    setShowModal(true);
  };

  const handleEverydayChange = () => {
    setEveryday(!everyday);
  };



  // const addProgram = () => {
  //   if (programs.length < 10) {
  //     setPrograms([...programs, { program: nextProgramId, value: [] }]);
  //   }
  // };

  // const deleteProgram = (index: number) => {
  //   const updatedPrograms = [...programs];
  //   updatedPrograms.splice(index, 1);
  //   setPrograms(updatedPrograms);
  // };


  // const handleIntervalClick = (
  //   programIndex: number,
  //   intervalIndex: number
  // ) => {
  //   setSelectedProgramIndex(programIndex);
  //   setSelectedIntervalIndex(intervalIndex);
  //   setSelectedIntervalMode(
  //     programs[programIndex].value[intervalIndex].mode
  //   );
  //   setSelectedIntervalStart(
  //     programs[programIndex].value[intervalIndex].start || ""
  //   );
  //   setSelectedIntervalEnd(
  //     programs[programIndex].value[intervalIndex].end || ""
  //   );
  //   setShowModal(true);
  // };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalSave = () => {
    if (selectedDay !== null && selectedProgramIndex !== null) {
      const scheduleExists = schedules.some(schedule => 
        schedule.day === selectedDay && schedule.channel === channel
      );
  
      let updatedSchedules;
      if (scheduleExists) {
        updatedSchedules = schedules.map(schedule => 
          schedule.day === selectedDay && schedule.channel === channel
            ? { ...schedule, program: selectedProgramIndex }
            : schedule
        );
      } else {
        const newSchedule = {
          day: selectedDay,
          channel: channel,
          program: selectedProgramIndex
        };
        updatedSchedules = [...schedules, newSchedule];
      }
  
      setSchedules(updatedSchedules);
      setShowModal(false);
    }
  };

  // const handleModalSave = () => {
  //   const updatedPrograms = [...programs];
  //   updatedPrograms[selectedProgramIndex].value[
  //     selectedIntervalIndex
  //   ].mode = selectedIntervalMode;
  //   updatedPrograms[selectedProgramIndex].value[
  //     selectedIntervalIndex
  //   ].start = selectedIntervalStart;
  //   updatedPrograms[selectedProgramIndex].value[
  //     selectedIntervalIndex
  //   ].end = selectedIntervalEnd;
  //   setPrograms(updatedPrograms);
  //   setShowModal(false);
  // };

  // const handleDeleteInterval = () => {
  //   const updatedPrograms = [...programs];
  //   updatedPrograms[selectedProgramIndex].value.splice(selectedIntervalIndex, 1);
  //   setPrograms(updatedPrograms);
  //   setShowModal(false);
  // };

  const handleAddProgram = () => {
    navigate('/dashboard/programs');
  };

  const renderSchedule = (day: number) => {
    const schedule = schedules.find(schedule => schedule.day === day && schedule.channel === channel);
    const program = schedule ? programs.find(p => p.program === schedule.program) : null;

    return (
      <div key={day} className="program-container" onClick={() => handleDayClick(day)}
        style = {{backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        cursor: "pointer"}}>
        
        <div className="program-title">
          <span>
            {schedule ? `Program ${schedule.program} for ${day === 7 ? 'Everyday' : _day[day]}` : `No Program for ${day === 7 ? 'Everyday' : _day[day]}`}
          </span>
        </div>
        <div className="time-scale" 
              style = {{ backgroundColor: colorConfigs.topbar.bg,
              color: colorConfigs.topbar.color}}>
            {Array.from({ length: 24 }).map((_, i) => (
              <div key={i} className="time-mark">
                {isMobile ? (i % 3 == 0 ? (`${i % 12}`) : ``):(i < 10 ? `0${i}` : `${i}`)}
              </div>
            ))}
        </div>
        <div className="schedule-bar">
          {program && program.value.map((interval, intervalIndex) => (
            <div
              className="interval"
              key={intervalIndex}
              style={{
                left: `${(parseInt(interval.start.split(":")[0]) * 60 +
                  parseInt(interval.start.split(":")[1])) /
                  1440 *
                  100}%`,
                width: `${((parseInt(interval.end.split(":")[0]) * 60 +
                  parseInt(interval.end.split(":")[1])) -
                  (parseInt(interval.start.split(":")[0]) * 60 +
                    parseInt(interval.start.split(":")[1]))) /
                  1440 *
                  100}%`,
                backgroundColor: colorMode[interval.mode]
              }}
            />
          ))}
        </div>
      </div>
    );
  };
  
  
  return (
    <div>
      <Legend channel={channel === 2 ? 0 : 1} />
      <div>
        <label>
          <input
            type="checkbox"
            checked={everyday}
            onChange={handleEverydayChange}
          />
          Everyday
        </label>
      </div>
      {everyday
        ? renderSchedule(7)
        : Array.from({ length: 7 }, (_, i) => renderSchedule(i))}
      <button type="button" className="save-button" onClick={handleSaveClick}>Save</button>
      {showModal && (
        <Modal onClose={handleModalClose}>
          <h2>Choose program</h2>
          {programs && programs.length > 0 ? (
            <div>
              <label>Select Program:</label>
              <select onChange={handleProgramChange} value={selectedProgramIndex}>
                {programs.map((item, index) => (
                  <option key={item.program} value={index}>
                    {item.program}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div>
              <p>No programs available.</p>
              <button onClick={handleAddProgram}>Add Program</button>
            </div>
          )}

          {(programs && programs.length > 0) && (
            <div key={selectedProgramIndex}>
              <h3 className="program-title">Program {programs[selectedProgramIndex].program}</h3>
              <div className="time-scale" 
                style = {{ backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color}}>
                {Array.from({ length: 24 }).map((_, i) => (
                  <div key={i} className="time-mark">
                    {isMobile ? (i % 3 == 0 ? (`${i % 12}`) : ``):(i < 10 ? `0${i}` : `${i}`)}
                  </div>
                ))}
              </div>
              <div className="schedule-bar">
                {programs[selectedProgramIndex].value.map(
                (interval: Interval, intervalIndex: number) => (
                  <React.Fragment key={intervalIndex}>
                    <div className="interval"
                      style={{
                        left: `${
                          (parseInt(interval.start.split(":")[0]) * 60 +
                            parseInt(interval.start.split(":")[1])) /
                          1440 *
                          100
                        }%`,
                        width: `${
                          ((parseInt(interval.end.split(":")[0]) * 60 +
                            parseInt(interval.end.split(":")[1])) -
                            (parseInt(interval.start.split(":")[0]) * 60 +
                              parseInt(interval.start.split(":")[1]))) /
                          1440 *
                          100
                        }%`,
                        backgroundColor: colorMode[interval.mode]
                      }}
                    />
                  </React.Fragment>
                )
              )}
              </div>
            </div>
          )}
          <br />
          <div>
            <StyledButton onClick={handleModalSave}>Save</StyledButton>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Schedules;