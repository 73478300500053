import React, { useState, useEffect, Fragment } from "react";

import { useSelector  } from "react-redux";
import { RootState } from "../../redux/store";
import MqttService from '../../services/mqtt/mqttService';

import { Box, Grid, Typography } from "@mui/material";
import { AccessTime, WbSunny, AcUnit, Today, Opacity, Thermostat, ThermostatAuto } from "@mui/icons-material"; 

import Monitor from './Monitor';
import { StringLiteral } from "typescript";

type Props = {};

const MonitorPage = ({ mqttService }: { mqttService: MqttService }) => {

  var message: string | undefined;
  const [activeTab, setActiveTab] = useState<string>('A');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  
  const devices = useSelector((state: RootState) => state.mqttState);
  const activeDev = useSelector((state: RootState) => state.activeDevState);

  const seasons = ['Winter', 'Summer'];
  const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  type DateTime = {
    s: number;
    d: number;
    m: number;
    md: number;
    h: number;
    min: number | undefined;
  };

  // Sets default React state
  const [date, setDate] = useState<DateTime | undefined>();
  const [temp, setTemp] = useState<number | undefined>();
  const [tempAux, setTempAux] = useState<number | undefined>();
  const [humid, setHumid] = useState<number | undefined>();

  console.log("ACTIVE DEV " + activeDev.activeDevState);


  useEffect(() => {
    const deviceIndex = devices.mqttState.findIndex((device) => device.id === activeDev.activeDevState);
    if (deviceIndex !== -1){
      if (devices.mqttState[deviceIndex].config.state?.date){
        message = devices.mqttState[deviceIndex].config.state?.date;
        if (message){
          var dateTime : DateTime;
          dateTime = JSON.parse(message);
          setDate(dateTime);
        }
        else
        {
          setDate(undefined);
        }
      }

      message = devices.mqttState[deviceIndex].config.state?.temp?.main;
      if (message !== undefined){
        const tempValue = parseFloat(message);
        if (!isNaN(tempValue)) {
          setTemp(Number(tempValue.toFixed(2)));
        } else {
          setTemp(undefined);
        }
      }
      else{
        setTemp(undefined);
      }

      message = devices.mqttState[deviceIndex].config.state?.temp?.aux;
      if (message !== undefined){
        const tempValue = parseFloat(message);
        if (!isNaN(tempValue)) {
          setTempAux(Number(tempValue.toFixed(2)));
        } else {
          setTempAux(undefined);
        }
      }
      else{
        setTempAux(undefined);
      }

      message = devices.mqttState[deviceIndex].config.state?.humid;
      if (message !== undefined){
        const tempValue = parseFloat(message);
        if (!isNaN(tempValue)) {
          setHumid(Number(tempValue.toFixed(2)));
        } else {
          setHumid(undefined);
        }
      }
      else{
        setHumid(undefined);
      }
    }
    else{
      setDate(undefined);
      setTemp(undefined);
      setTempAux(undefined);
      setHumid(undefined);
    }
  }, [devices, activeDev.activeDevState]);


  return (
    <div>
      <Box sx={{ p: 2, bgcolor: 'background.paper', boxShadow: 1, borderRadius: 4, margin: 1 }}>
      <Typography variant="h5" gutterBottom>
        System Parameters
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Season:  </Typography>
                <Typography variant="body1">
                {date?.s !== undefined && date?.s < seasons.length ? 
                (date?.s === 0 ? <AcUnit sx={{ mr: 1 }} /> : <WbSunny sx={{ mr: 1 }} />) : 
                " unknown"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Today sx={{ mr: 1 }} />
                {(date?.d !== undefined && date?.md !== undefined && date?.m !== undefined) &&
                  (date?.d < week.length && date?.m - 1 < month.length) ?
                  `${week[date?.d]}, ${date?.md} ${month[date?.m - 1]}` : " unknown"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <AccessTime sx={{ mr: 1 }} />
                {date?.h !== undefined && date?.min != undefined ? `${date?.h}:${date?.min > 9 ? date?.min : `0${date?.min}`}` : " unknown"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Thermostat sx={{ mr: 1 }} />
                {temp !== undefined ? `${temp} °C` : " unknown "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <ThermostatAuto sx={{ mr: 1 }} />
                {tempAux !== undefined ? `${tempAux} °C` : " unknown"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Opacity sx={{ mr: 1 }} />
                {humid !== undefined ? `${humid} %` : " unknown"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Box>
      <div className="tabs">
        <button onClick={() => handleTabClick('A')} className={activeTab === 'A' ? 'active' : ''}>
          A
        </button>
        <button onClick={() => handleTabClick('B')} className={activeTab === 'B' ? 'active' : ''}>
          B
        </button>
        <button onClick={() => handleTabClick('T')} className={activeTab === 'T' ? 'active' : ''}>
          T
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'A' && <Monitor mqttService={mqttService} channel={0} />}
        {activeTab === 'B' && <Monitor mqttService={mqttService} channel={1} />}
        {activeTab === 'T' && <Monitor mqttService={mqttService} channel={2} />}
      </div>
    </div>
  );
};

export default MonitorPage;