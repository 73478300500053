import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type activeDevState = {
  activeDevState: string;
};

const initialState: activeDevState = {
  activeDevState: ""
};

export const activeDevStateSlice = createSlice({
  name: "activeDevState",
  initialState,
  reducers: {
    setActiveDevState: (state, action: PayloadAction<string>) => {
      state.activeDevState = action.payload;
    }
  }
});

export const {
  setActiveDevState
} = activeDevStateSlice.actions;

export default activeDevStateSlice.reducer;