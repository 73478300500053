import { strict } from 'assert';
import React from 'react';

type Props = {};

const DevicesPage = (props: Props) => {

  let str = localStorage.getItem('devices');

  if (str)
  {
    let devices = JSON.parse(str);
    let items = devices.map((device : string) =>
      <li>{device}</li>
    );

    return (
      <div>
        Linked devices: 
        <ul>{items}</ul>
      </div>
    );
  }
  else
  {
    return (
      <div>No devices</div>
    );
  }
};

export default DevicesPage;