const sizeConfigs = {
  sidebar: {
    width: "240px"
  },
  sidebar_collapse: {
    width: "60px"
  },
  topbar: {
    height: "65px"
  },
};

export default sizeConfigs;