import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";

import useToken from './services/UseToken/useToken';
import Login from './pages/Login/Login';
import 'bootstrap/dist/css/bootstrap.min.css';

import MqttService from './services/mqtt/mqttService';
import Mqtt from './services/mqtt/mqtt';

const mqttService = MqttService.getInstance();

function App() {

  const {token, setToken} = useToken();
  //const [token, setToken] = useState<string | undefined>();

  if(!token) {
    return (<Login 
              setToken={setToken}
           />)
  }
  
  return (
    <>
      <Mqtt mqttService={mqttService} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            {routes}
          </Route>
        </Routes>
      </BrowserRouter>
  
    </>
  );
}

export default App;