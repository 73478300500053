import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import MqttService from "../../services/mqtt/mqttService";
import Modal from "../../components/common/Modal";

import colorConfigs from "../../configs/colorConfigs";

import { styled } from "@mui/system";
import Button from "@mui/material/Button";

import { Delete, MoreTime, MoreHoriz, AddCircle } from '@mui/icons-material';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import "./style.css";

import Legend from "./Legend"

interface Interval {
  start: string;
  end: string;
  mode: number;
}

interface Program {
  program: number;
  value: Interval[];
}

interface IMode {
  [key: number]: string
}

var modes: IMode = {
  0: "ECO",
  1: "COMFORT",
  2: "AUTO",
  3: "OFF",
  4: "ON",
  6: "UNDEFINED",
  255: "RESET_STAT"
};

var colorMode: IMode = {
  0: "green",
  1: "red",
  2: "blue", 
  3: "black",
  4: "white",
  6: "yellow"
};

var ch: IMode = {
  0: "A",
  1: "B",
  2: "T"
};

const useStyles = () => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: "8px",
  },
});

const StyledButton = styled(Button)({
  margin: "8px",
});


interface ProgramsProps {
  mqttService: MqttService;
  channel: number;
}

const Programs: React.FC<ProgramsProps> = ({ mqttService, channel }) => {

  const activeDev = useSelector((state: RootState) => state.activeDevState);
  const devices = useSelector((state: RootState) => state.mqttState);
  var deviceIndex = devices.mqttState.findIndex(
    (device) => device.id === activeDev.activeDevState
  );
  const [programs, setPrograms] = useState<Program[][]>([[], []]);
  const [nextProgramId, setNextProgramId] = useState<number>(0);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedProgramIndex, setSelectedProgramIndex] = useState<number>(-1);
  const [selectedIntervalIndex, setSelectedIntervalIndex] = useState<number>(-1);
  const [selectedIntervalMode, setSelectedIntervalMode] = 
    useState<number>(channel ?
      Number(Object.keys(modes).find(key => modes[Number(key)] === "ECO")) || 0 : 
      Number(Object.keys(modes).find(key => modes[Number(key)] === "OFF")) || 3 );
  const [selectedIntervalStart, setSelectedIntervalStart] = useState<string>("");
  const [selectedIntervalEnd, setSelectedIntervalEnd] = useState<string>("");

  const [showTableModal, setShowTableModal] = useState<boolean>(false);
  const [modalScrollable, setModalScrollable] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const correctAndSortIntervals = (intervals: Interval[]): Interval[] => {
  // 1. Sort intervals by start time
    intervals.sort((a, b) => (a.start > b.start ? 1 : -1));

    // 2. Correct intervals where end is less than start and enforce non-overlapping
    for (let i = 0; i < intervals.length; i++) {
      if (intervals[i].end <= intervals[i].start) {
        const [startHour, startMinute] = intervals[i].start.split(':').map(Number);
        let endHour = startHour + 1;
        let endMinute = startMinute;
        if (endHour === 24) {
          endMinute = 0;
        }
        intervals[i].end = `${String(endHour).padStart(2, '0')}:${String(endMinute).padStart(2, '0')}`;
      }
    }

    let i = 0;
    while (i < intervals.length - 1) {
      const current = intervals[i];
      const next = intervals[i + 1];

      const [currentEndHour, currentEndMinute] = current.end.split(':').map(Number);
      const [nextStartHour, nextStartMinute] = next.start.split(':').map(Number);

      const currentEndInMinutes = currentEndHour * 60 + currentEndMinute;
      const nextStartInMinutes = nextStartHour * 60 + nextStartMinute;

      // If current interval end is greater than or equal to next interval start
      if (currentEndInMinutes > nextStartInMinutes) {
        const [currentStartHour, currentStartMinute] = current.start.split(':').map(Number);
        const [nextEndHour, nextEndMinute] = next.end.split(':').map(Number);

        const currentStartInMinutes = currentStartHour * 60 + currentStartMinute;
        const nextEndInMinutes = nextEndHour * 60 + nextEndMinute;

        // If current interval completely covers the next interval
        if (currentStartInMinutes <= nextStartInMinutes && currentEndInMinutes >= nextEndInMinutes) {
          intervals.splice(i + 1, 1); // Remove the next interval
        } else if (nextStartInMinutes <= currentStartInMinutes && nextEndInMinutes >= currentEndInMinutes) {
          intervals.splice(i, 1); // Remove the current interval
        } else {
          // If current interval partially overlaps with the next interval
          if (currentEndInMinutes > nextStartInMinutes) {
            current.end = next.start; // Adjust current interval end time
          }
          i++; // Move to the next interval
        }
      } else {
        i++; // Move to the next interval
      }
    }

    // 3. Merge adjacent intervals with the same mode
    i = 0;
    while (i < intervals.length - 1) {
      const current = intervals[i];
      const next = intervals[i + 1];

      if (current.mode === next.mode && current.end === next.start) {
        current.end = next.end; // Merge intervals
        intervals.splice(i + 1, 1); // Remove the next interval
      } else {
        i++;
      }
    }

    return intervals;
  };


  useEffect(() => {
    deviceIndex = devices.mqttState.findIndex((device) => device.id === activeDev.activeDevState);
    if (deviceIndex !== -1) {
      const newPrograms = [[], []];

      if (devices.mqttState[deviceIndex].config?.files?.progT !== undefined) {
        newPrograms[0] = JSON.parse(
          devices.mqttState[deviceIndex].config?.files?.progT as string
        );

        for (let i = 0; i < newPrograms[0].length; i++) {
          const program = newPrograms[0][i] as Program;
          (newPrograms[0][i] as Program).value = correctAndSortIntervals(program.value);
        }
      }
      if (devices.mqttState[deviceIndex].config?.files?.progAB !== undefined) {
        newPrograms[1] = JSON.parse(
          devices.mqttState[deviceIndex].config?.files?.progAB as string
        );

        for (let i = 0; i < newPrograms[1].length; i++) {
          const program = newPrograms[1][i] as Program;
          (newPrograms[1][i] as Program).value = correctAndSortIntervals(program.value);
        }
      }
      setPrograms(newPrograms);
    }
    else{
      setPrograms([[], []]);
    }
  }, [activeDev.activeDevState/*, devices*/]);

  useEffect(() => {
    if (programs[channel].length === 0) return;
    let minId = 0;
    for (let i = 1; i <= programs[channel].length; i++) {
      if (!programs[channel].find((program) => program.program === i)) {
        minId = i;
        break;
      }
    }
    setNextProgramId(minId);
  }, [programs, channel]);

  useEffect(() => {
    if (programs[channel][selectedProgramIndex]?.value.length > 3) {
      setModalScrollable(true);
    } else {
      setModalScrollable(false);
    }
  }, [programs, selectedProgramIndex, channel]);

  const handleSaveClick = () => {
    if (deviceIndex !== -1 && programs[channel]) {

      let prog = programs[channel];
      for (let i = 0; i < programs[channel].length; i++) {
        const program = programs[channel][i] as Program;
        prog[i].value = correctAndSortIntervals(program.value);
      }

      const topic = activeDev.activeDevState + (channel === 0 ? '/desired/files/progT' : '/desired/files/progAB');
      const message = JSON.stringify(prog);

      console.log(message);
      console.log("topic" + topic);
      const qos = 2;
      mqttService.publishMessage(topic, message, qos, false);
    }
  };

  const classes = useStyles();

  const addProgram = () => {
    if (programs[channel].length < 10) {
      const newPrograms = [...programs];
      newPrograms[channel].push({ program: nextProgramId, value:
         [{start: "00:00", end: "24:00", mode: Number(Object.keys(modes).find(key => modes[Number(key)] === "UNDEFINED"))}] });
      setPrograms(newPrograms);
    }
  };

  const deleteProgram = (index: number) => {
    const updatedPrograms = [...programs];
    updatedPrograms[channel].splice(index, 1);
    setPrograms(updatedPrograms);
  };

  const getNewMode = (intervalIdx : number, program : Program, firstMode : number, lastMode : number) => {
    const prevMode = intervalIdx > 0 ? program.value[intervalIdx - 1].mode : lastMode;
    const nextMode = intervalIdx < program.value.length - 1 ? program.value[intervalIdx + 1].mode : firstMode;
    return prevMode + 1 <= lastMode ? (prevMode + 1 !== nextMode ? prevMode + 1 :
       ( prevMode + 2 <= lastMode ?  prevMode + 2 : firstMode)) : (nextMode != firstMode ? firstMode : firstMode + 1);
  }

  const addInterval = (index: number, channel: number) => {
    const newPrograms = [...programs];
    const program = newPrograms[channel][index];
    const firstMode: number = channel === 0 ? 
      Number(Object.keys(modes).find(key => modes[Number(key)] === "ECO")) :
      Number(Object.keys(modes).find(key => modes[Number(key)] === "OFF"));
      const lastMode: number = channel === 0 ? 
      Number(Object.keys(modes).find(key => modes[Number(key)] === "AUTO")) :
      Number(Object.keys(modes).find(key => modes[Number(key)] === "ON"));

    
    if (program.value.length === 0){    
      program.value.push({ start: "00:00", end: "24:00", mode: firstMode });
      return;
    }
    else{
      let emptyIndex = -1;
      let startVal: string = "00:00", endVal: string = "24:00";
      let width = 0;
      // 1. Find empty intervals
      for (let i = -1; i < program.value.length; i++) {
        let startHourNext : number;
        let startMinuteNext : number;
        let endHour : number;
        let endMinute : number;

        if (i === program.value.length - 1){
          const interval = program.value[i];
          [endHour, endMinute] = interval.end.split(':').map(Number);
          startVal = interval.end;

          startHourNext = 24;
          startMinuteNext = 0;
          endVal = "24:00";
        }
        else if (i === -1){
          endHour = 0;
          endMinute = 0;
          startVal = "00:00";

          const nextInterval = program.value[i + 1];
          [startHourNext, startMinuteNext] = nextInterval.start.split(':').map(Number);
          endVal = nextInterval.start;
        }
        else{
          const interval = program.value[i];
          [endHour, endMinute] = interval.end.split(':').map(Number);
          startVal = interval.end;

          const nextInterval = program.value[i + 1];
          [startHourNext, startMinuteNext] = nextInterval.start.split(':').map(Number);
          endVal = nextInterval.start;
        }
       
        const endInMinutes = endHour * 60 + endMinute;
        const startNextInMinutes = startHourNext * 60 + startMinuteNext;

        width = (startNextInMinutes - endInMinutes) / 60;
        if (width >= 1) {
          emptyIndex = i + 1;
            
          if (emptyIndex === program.value.length){
            program.value.push({ start: startVal, end: endVal, mode: firstMode });
          }
          else{
            program.value.splice(emptyIndex, 0, { start: startVal, end: endVal, mode: firstMode });
          }
          program.value[emptyIndex].mode = getNewMode(i, program, firstMode, lastMode);
          setPrograms(newPrograms);
          return;          
        }
      }
      // 2. Find intervals with mode === "UNDEFINED"
      for (let i = 0; i < program.value.length; i++) {
        const interval = program.value[i];
        if (interval.mode ===  Number(Object.keys(modes).find(key => modes[Number(key)] === "UNDEFINED"))){
          interval.mode = getNewMode(i, program, firstMode, lastMode);

          setPrograms(newPrograms);
          return;
        }
      }
    }
  
    if (program.value.length < 24) {
      // 3. Find the first interval with width > 1 hour and split it
      for (let i = 0; i < program.value.length; i++) {
        const interval = program.value[i];
        const [startHour, startMinute] = interval.start.split(':').map(Number);
        const [endHour, endMinute] = interval.end.split(':').map(Number);
  
        const startInMinutes = startHour * 60 + startMinute;
        const endInMinutes = endHour * 60 + endMinute;
        const intervalWidth = (endInMinutes - startInMinutes) / 60;

        if (intervalWidth > 1) {
          const newStartHour = startHour + 1;
          const newStart = `${String(newStartHour).padStart(2, '0')}:${String(startMinute).padStart(2, '0')}`;

          const newInterval = { start: interval.start, end: newStart, mode: firstMode };
          interval.start = newStart;  

          program.value.splice(i, 0, newInterval);  
          program.value[i].mode = getNewMode(i, program, firstMode, lastMode);
          setPrograms(newPrograms);
          return;
        }
      }
    }
  };

  const intervalChoose = (
  programIndex: number,
  intervalIndex: number
  ) => {
    if (programs[channel] && programs[channel][programIndex] && programs[channel][programIndex].value[intervalIndex]) {
      setSelectedIntervalIndex(intervalIndex);
      setSelectedIntervalMode(
        programs[channel][programIndex].value[intervalIndex].mode !== undefined ?
        programs[channel][programIndex].value[intervalIndex].mode : 0
      );
      setSelectedIntervalStart(
        programs[channel][programIndex].value[intervalIndex].start !== undefined ?
        programs[channel][programIndex].value[intervalIndex].start : ""
      );
      setSelectedIntervalEnd(
        programs[channel][programIndex].value[intervalIndex].end !== undefined ? 
        programs[channel][programIndex].value[intervalIndex].end : ""
      );
    }
  };

  const intervalSave  = () => {
    if (programs[channel] && programs[channel][selectedProgramIndex] && programs[channel][selectedProgramIndex].value[selectedIntervalIndex]) {
      const updatedPrograms = [...programs];

      updatedPrograms[channel][selectedProgramIndex].value[selectedIntervalIndex].mode = selectedIntervalMode;
      updatedPrograms[channel][selectedProgramIndex].value[selectedIntervalIndex].start = selectedIntervalStart;
      updatedPrograms[channel][selectedProgramIndex].value[selectedIntervalIndex].end = selectedIntervalEnd;

      updatedPrograms[channel][selectedProgramIndex].value = correctAndSortIntervals(updatedPrograms[channel][selectedProgramIndex].value);
      setPrograms(updatedPrograms);
    }
  };

  const intervalDelete = (intervalIndex: number) => {
    if (programs[channel] && programs[channel][selectedProgramIndex] && programs[channel][selectedProgramIndex].value[selectedIntervalIndex]) {
      const updatedPrograms = [...programs];
      updatedPrograms[channel][selectedProgramIndex].value[intervalIndex].mode = 
      Number(Object.keys(modes).find(key => modes[Number(key)] === "UNDEFINED")) || 6;
      setPrograms(updatedPrograms);
    }
  };

  //grafic bar interface
  const handleIntervalBarClick = (
    programIndex: number,
    intervalIndex: number
  ) => {
    setSelectedProgramIndex(programIndex);
    intervalChoose(programIndex, intervalIndex);
    setShowModal(true);
  };

  const handleIntervalBarSave = () => {
    intervalSave();
    setShowModal(false);
  };

  const handleIntervalBarDelete = () => {
    intervalDelete(selectedIntervalIndex);
    setShowModal(false);
  };

  const handleBarModalClose = () => {
    setShowModal(false);
  };
//table interface
  const handleIntervalTableStartClick = (
    intervalIndex: number,
    start: string
  ) => {
    const updatedPrograms = [...programs];
    updatedPrograms[channel][selectedProgramIndex].value[intervalIndex].start = start;
    updatedPrograms[channel][selectedProgramIndex].value = correctAndSortIntervals(updatedPrograms[channel][selectedProgramIndex].value);
    setPrograms(updatedPrograms);
  };

  const handleIntervalTableEndClick = (
    intervalIndex: number,
    end: string
  ) => {
    const updatedPrograms = [...programs];
    updatedPrograms[channel][selectedProgramIndex].value[intervalIndex].end = end;
    updatedPrograms[channel][selectedProgramIndex].value = correctAndSortIntervals(updatedPrograms[channel][selectedProgramIndex].value);
    setPrograms(updatedPrograms);
  };

  const handleIntervalTableModeClick = (
    intervalIndex: number,
    mode: number
  ) => {
    const updatedPrograms = [...programs];
    updatedPrograms[channel][selectedProgramIndex].value[intervalIndex].mode = mode;
    updatedPrograms[channel][selectedProgramIndex].value = correctAndSortIntervals(updatedPrograms[channel][selectedProgramIndex].value);
    setPrograms(updatedPrograms);
  };

  const handleIntervalTableDelete = (intervalIndex: number) => {
    intervalDelete(intervalIndex);
  };

  const handleIntervalTableAdd = () => {
    addInterval(selectedProgramIndex, channel);
  }

  const handleTableModalShow = (programIndex: number) => {
    setSelectedProgramIndex(programIndex);
    setShowTableModal(true);
  };

  const handleTableModalClose = () => {
    setShowTableModal(false);
  };

  return (
    <div>
      <Legend channel={channel} />

      {programs[channel] && programs[channel].map((program, programIndex) => (
        <div key={programIndex} className="program-container" 
          style = {{backgroundColor: colorConfigs.topbar.bg,
          color: colorConfigs.topbar.color}}>
          <h3 className="program-title">Program {programIndex + 1}
            <div className="actions-container">
              <div className="button-container">
                <button onClick={() => addInterval(programIndex, channel)}><MoreTime /></button>
              </div>
              <div className="button-container">
                <button onClick={() => deleteProgram(programIndex)}><Delete /></button>
              </div>
              <div className="button-container">
                <button onClick={() => handleTableModalShow(programIndex)}><MoreHoriz /></button>
              </div>
            </div>
          </h3>
          <div className="time-scale" 
              style = {{ backgroundColor: colorConfigs.topbar.bg,
              color: colorConfigs.topbar.color}}>
            {Array.from({ length: 24 }).map((_, i) => (
              <div key={i} className="time-mark">
                {isMobile ? (i % 3 == 0 ? (`${i % 12}`) : ``):(i < 10 ? `0${i}` : `${i}`)}
              </div>
            ))}
          </div>
          <div  className="schedule-bar">
            {program.value && program.value.map((interval: Interval, intervalIndex: number) => (
              <React.Fragment key={intervalIndex}>
                <div
                  className="interval"
                  style={{
                    left: `${(parseInt(interval.start.split(":")[0]) * 60 + parseInt(interval.start.split(":")[1])) / 1440 * 100}%`,
                    width: `${((parseInt(interval.end.split(":")[0]) * 60 + parseInt(interval.end.split(":")[1])) - (parseInt(interval.start.split(":")[0]) * 60 + parseInt(interval.start.split(":")[1]))) / 1440 * 100}%`,
                    backgroundColor: colorMode[interval.mode]
                  }}
                  onClick={() => handleIntervalBarClick(programIndex, intervalIndex)}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
      {programs[channel] && programs[channel].length < 10 && <button className="add-button" onClick={addProgram}><AddCircle/></button>}
      <button type="button" className="save-button" onClick={handleSaveClick}>Save</button>
      
      {showModal && (
        <Modal onClose={handleBarModalClose}>
          <h2>Edit Interval</h2>
          <label>Mode:</label>
          <select
            value={selectedIntervalMode}
            onChange={(e) => setSelectedIntervalMode(parseInt(e.target.value))}
          >
            {channel === 0 ?
              <>
                <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "UNDEFINED")) || 6} disabled hidden>Select mode...</option>
                <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "ECO")) || 0}>ECO</option>
                <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "COMFORT")) || 1}>COMFORT</option>
                <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "ECO")) || 2}>AUTO</option>
              </>
              :
              <>
                <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "UNDEFINED")) || 6} disabled hidden>Select mode...</option>
                <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "ON")) || 3}>ON</option>
                <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "OFF")) || 4}>OFF</option>
              </>
            }
          </select>
          <br />
          <label>Start:</label>
          <input
            type="text"
            value={selectedIntervalStart}
            onChange={(e) => setSelectedIntervalStart(e.target.value)}
          />
          <br />
          <label>End:</label>
          <input
            type="text"
            value={selectedIntervalEnd}
            onChange={(e) => setSelectedIntervalEnd(e.target.value)}
          />
          <br />
          <div>
            <StyledButton variant="contained" onClick={handleIntervalBarSave}>Save</StyledButton>
            <StyledButton variant="contained" onClick={handleIntervalBarDelete}>Delete Interval</StyledButton>
          </div>
        </Modal>
        )}


        {showTableModal && (
        <Modal onClose={handleTableModalClose}>
        <div className="modal-content">
          <h2>Intervals for Program {programs[channel][selectedProgramIndex]?.program}</h2>
          <div className={`interval-table-container ${modalScrollable ? 'scrollable' : ''}`}>
          <table className="interval-table">
            <thead>
              <tr>
                <th>Start</th>
                <th>End</th>
                <th>Mode</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {programs[channel][selectedProgramIndex]?.value.map((interval, intervalIndex) => (
                <tr key={intervalIndex}>
                  <td>
                    <input 
                      type="text" 
                      value={interval.start} 
                      onChange={(e) => handleIntervalTableStartClick(intervalIndex, e.target.value)}
                    />
                  </td>
                  <td>          
                    <input
                      type="text"
                      value={interval.end}
                      onChange={(e) => handleIntervalTableEndClick(intervalIndex, e.target.value)}
                    />
                  </td>
                  <td>
                    <select
                      value={interval.mode}
                      onChange={(e) => handleIntervalTableModeClick(intervalIndex, parseInt(e.target.value))}
                    >
                      {channel === 0 ?
                      <>
                        <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "UNDEFINED")) || 6} disabled hidden>Select mode...</option>
                        <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "ECO")) || 0}>ECO</option>
                        <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "COMFORT")) || 1}>COMFORT</option>
                        <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "ECO")) || 2}>AUTO</option>
                      </>
                      :
                      <>
                        <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "UNDEFINED")) || 6} disabled hidden>Select mode...</option>
                        <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "ON")) || 3}>ON</option>
                        <option value={Number(Object.keys(modes).find(key => modes[Number(key)] === "OFF")) || 4}>OFF</option>
                      </>
                    }
                    </select>
                  </td>
                  <td>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      startIcon={<Delete />}
                      className="StyledButtonDelete"
                      onClick={() => handleIntervalTableDelete(intervalIndex)}
                    >
                    </StyledButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <StyledButton
            variant="contained"
            onClick={handleIntervalTableAdd}
          >
            Add interval
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={handleTableModalClose}
          >
            Close
          </StyledButton>
        </div>
        </Modal>
      )}
    </div>
  );
};

export default Programs;