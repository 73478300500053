import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import {ISetToken} from '../../services/UseToken/useToken';

//TODO
//server hosted
//'https://andrii.ddns.net/api/v1/login'
//local hosted
//'http://localhost:8080/login'
//'http://192.168.0.208:8080/login' (in local network - mobile dev testing)

async function loginUser(credentials : {username : string | undefined; password : string | undefined}) {
 
    return fetch('https://andrii.ddns.net:8081/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

  // interface Props{
  //    setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  // }

export default function Login( {setToken} :  ISetToken) {
  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();

  const handleSubmit = async (e : React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    // setToken(JSON.stringify(token));
    setToken(token);
  }

  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)}/>
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}