import React, { useState, useEffect, useRef  } from "react";

import { useSelector  } from "react-redux";
import { RootState } from "../../redux/store";

import MqttService from '../../services/mqtt/mqttService';
import _zones from "./zones.json";
import "./style.css"

type Props = {};

type Therm = {
  defrostLo: number;
  defrostHi: number;
  tempWinLo: number;
  tempWinHi: number;
  tempSumLo: number;
  tempSumHi: number;
  consumption: number;
  tariff: number;
  autoSeason: number;
  summerDate: string
  winterDate: string
  timeoutPIR: number;
  tempHysteresis: number;
  autoShutOffEna: number;
  defrostEna: number;
  openWindowCtrl: number;
};

type Tim = {
  channel: number;
  consumption: number;
  tariff: number;
  timeoutPIR: number;
  lockEna: number;
  lockPol: number;
  lockHold: number;
  rfid1: string;
  rfidDescription1: string;
  rfid2: string;
  rfidDescription2: string;
};

type Settings = {
  description: string;
  webPassw: string;
  masterRFID: string;
  timezone: string;
  timezoneloc: string;
  mqtt_broker_uri: string;
  irCarrierFreq: number;
  irCarrierDC: number;
  dateFormat: number;
  bcklightLCD: number;
  auxSensType: number;
  altRfidButCh: number;
  ble1: string;
  ble2: string;
  ble1Ch: number;
  ble2Ch: number;
  thermostat: Therm;
  timer: Tim[];
};

const SettingsPage = ({ mqttService }: { mqttService: MqttService }) => {
  
  const messageRef = useRef<string | undefined>();
  const devices = useSelector((state: RootState) => state.mqttState);
  const activeDev = useSelector((state: RootState) => state.activeDevState);

  const formRef = useRef<HTMLFormElement>(null); // Реф для доступа к форме
  // Состояние для сообщения об ошибке
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Sets default React state
  const [settings, setSettings] = useState<Partial<Settings | undefined>>({});
  const [formValues, setFormValues] = useState<Settings>({
    description: settings?.description || "", // описание прибора (где расположен)
    mqtt_broker_uri: settings?.mqtt_broker_uri || "", // Значение MQTT брокера
    webPassw: settings?.webPassw || "", // Пароль веб-интерфейса
    masterRFID: settings?.masterRFID || "", // Мастер RFID
    timezone: settings?.timezone || "", // Часовой пояс
    timezoneloc: settings?.timezoneloc || "", // Местное время
    irCarrierFreq: settings?.irCarrierFreq || 0, // Частота несущей инфракрасного порта
    irCarrierDC: settings?.irCarrierDC || 0, // Скважность несущей инфракрасного порта
    dateFormat: settings?.dateFormat || 0, // Формат даты на LCD
    bcklightLCD: settings?.bcklightLCD || 0, // Подсветка LCD
    auxSensType: settings?.auxSensType || 0, // Вспомогательный тип сенсора
    altRfidButCh: settings?.altRfidButCh !== undefined ? settings.altRfidButCh : -1, // Альтернативный канал RFID
    ble1: settings?.ble1 || "", // BLE1
    ble2: settings?.ble2 || "", // BLE2
    ble1Ch: settings?.ble1Ch !== undefined ? settings?.ble1Ch : -1, // Канал BLE1
    ble2Ch: settings?.ble2Ch !== undefined ? settings?.ble2Ch : -1, // Канал BLE2
    thermostat: settings?.thermostat ?  { // Параметры термостата
      defrostLo: settings?.thermostat.defrostLo || 0,
      defrostHi: settings?.thermostat.defrostHi || 0,
      tempWinLo: settings?.thermostat.tempWinLo || 0,
      tempWinHi: settings?.thermostat.tempWinHi || 0,
      tempSumLo: settings?.thermostat.tempSumLo || 0,
      tempSumHi: settings?.thermostat.tempSumHi || 0,
      consumption: settings?.thermostat.consumption || 0,
      tariff: settings?.thermostat.tariff || 0,
      autoSeason: settings?.thermostat.autoSeason || 0,
      summerDate: settings?.thermostat.summerDate || "",
      winterDate: settings?.thermostat.winterDate || "",
      timeoutPIR: settings?.thermostat.timeoutPIR || 0,
      tempHysteresis: settings?.thermostat.tempHysteresis || 0,
      autoShutOffEna: settings?.thermostat.autoShutOffEna || 0,
      defrostEna: settings?.thermostat.defrostEna || 0,
      openWindowCtrl: settings?.thermostat.openWindowCtrl || 0
    } : {
      defrostLo: 0,
      defrostHi: 0,
      tempWinLo: 0,
      tempWinHi: 0,
      tempSumLo: 0,
      tempSumHi: 0,
      consumption: 0,
      tariff: 0,
      autoSeason: 0,
      summerDate: "",
      winterDate: "",
      timeoutPIR: 0,
      tempHysteresis: 0,
      autoShutOffEna: 0,
      defrostEna: 0,
      openWindowCtrl: 0
    },
    timer: settings?.timer ? settings.timer.map((item: any) => ({ // Параметры таймера
      channel: item.channel || 0,
      consumption: item.consumption || 0,
      tariff: item.tariff || 0,
      timeoutPIR: item.timeoutPIR || 0,
      lockEna: item.lockEna || 0,
      lockPol: item.lockPol || 0,
      lockHold: item.lockHold || 0,
      rfid1: item.rfid1 || "",
      rfidDescription1: item.rfidDescription1 || "",
      rfid2: item.rfid2 || "",
      rfidDescription2: item.rfidDescription2 || ""
    })) :
    [
      {
        channel: 0,
        consumption: 0,
        tariff: 0,
        timeoutPIR: 0,
        lockEna: 0,
        lockPol: 0,
        lockHold: 0,
        rfid1: "",
        rfidDescription1: "",
        rfid2: "",
        rfidDescription2: ""
      },
      {
        channel: 1,
        consumption: 0,
        tariff: 0,
        timeoutPIR: 0,
        lockEna: 0,
        lockPol: 0,
        lockHold: 0,
        rfid1: "",
        rfidDescription1: "",
        rfid2: "",
        rfidDescription2: ""
      }
    ]
    });

  const [zones, setZones] = useState<string[]>([]);

  useEffect(() => {
    // Преобразуем объект _zones в массив строк
    const zonesArray = Object.keys(_zones);
    setZones(zonesArray);
  }, []);

  useEffect(() => {
    const deviceIndex = devices.mqttState.findIndex((device) => device.id === activeDev.activeDevState);
    if (deviceIndex !== -1){
      if (devices.mqttState[deviceIndex].config?.files?.settings){
        messageRef.current = devices.mqttState[deviceIndex].config?.files?.settings;
        
        if (messageRef.current){
          const settings: Settings = JSON.parse(messageRef.current);
          setSettings(settings);
        }
        else{
          setSettings(undefined);
        }
      }
      else{
        setSettings(undefined);
      }
    }
    else{
      setSettings(undefined);
    }
  }, [activeDev.activeDevState/*, devices*/]);


  const validateFields = () => {
    if (formRef.current) {
      const formElements = formRef.current.elements;
      let isError = false;

      // Пройдемся по всем элементам формы
      for (let i = 0; i < formElements.length; i++) {
        const el = formElements[i];

        if (el.tagName === "INPUT") {
          const element = formElements[i] as HTMLInputElement;
          // Если поле обязательно для заполнения и его значение пустое
          if (element.required && !element.value.trim()) {
            // Устанавливаем сообщение об ошибке и помечаем флаг isError как true
            setErrorMessage(`Ошибка: Поле "${element.name}" обязательно для заполнения`);
            isError = true;
            break; // Прекращаем проверку остальных полей после первой ошибки
          }
          if(element.pattern){
            const pattern = new RegExp(element.pattern);
            // Если значение поля не соответствует паттерну
            if (!pattern.test(element.value)) {
              // Устанавливаем сообщение об ошибке и помечаем флаг isError как true
              setErrorMessage(`Ошибка: Неверный формат для поля ${element.name}`);
              isError = true;
              break; // Прекращаем проверку остальных полей после первой ошибки
            }
          }
        }
        else if (el.tagName === "SELECT") {
          // Извлекаем выбранное значение
          const element = formElements[i] as HTMLSelectElement;
          // Проверяем, что значение выбрано (если это требуется)
          if (element.required && !element.value.trim()) {
            setErrorMessage(`Ошибка: Поле "${element.name}" обязательно для выбора`);
            isError = true;
            break;
          }
        }
      }

      // Если нет ошибок, сформируем JSON объект
      if (!isError) {
        const json = { ...formValues }; // Создаем копию объекта formValues
        // Формируем JSON объект
        // const json: any = {};
        // for (let i = 0; i < formElements.length; i++) {
        //   const el = formElements[i] as HTMLInputElement;

        //   if (el.tagName === "SELECT" && el.name) {
        //     const element = formElements[i] as HTMLSelectElement;
        //     if (element.name === "timezoneloc"){
        //       json["timezoneloc"] = element.value;
        //       json["timezone"] = (_zones as any)[element.value];
        //     }
        //     else{
        //       json[element.name] = Number(element.value);
        //     }
        //   }
        //   else if (el.tagName === "INPUT" && el.name) {
        //     const element = formElements[i] as HTMLInputElement;
        //     const [parentName, childName, index] = element.name.split('.'); // Разделяем имя элемента на родительское и дочернее
        //     const parentObj = json[parentName] || {};

        //     if (index !== undefined) {
        //       if(parentName && childName){
        //         if (!json[parentName]) {
        //           json[parentName] = [];
        //         }
        //         if (!json[parentName][index]) {
        //           json[parentName][index] = {};
        //         }
        //         json[parentName][index][childName] = element.value;
        //       }
        //     } else {

        //       if (childName) {
        //         if (!json[parentName]) {
        //           json[parentName] = {};
        //         }
        //         // Добавляем значение вложенному объекту
        //         if (element.type === "checkbox") {
        //           json[parentName][childName] = element.checked ? 1 : 0;
        //         } else if (element.type === "text") {
        //           json[parentName][childName] = element.value;
        //         } else {
        //           json[parentName][childName] = Number(element.value);
        //         }
        //       } else {
        //         // Если нет дочернего элемента, добавляем просто как значение
        //         if (element.type === "checkbox") {
        //           json[element.name] = element.checked ? 1 : 0;
        //         } else {
        //           json[element.name] = element.value;
        //         }
        //       }
        //     }
        //   }
      //  }

        // Ваша логика для отправки JSON объекта по MQTT
        // console.log("JSON объект:", json);
        console.log(JSON.stringify(json));
        const topic = activeDev.activeDevState + '/desired/files/settings';
        const message = JSON.stringify(json);
        const qos = 2;
        mqttService.publishMessage(topic, message, qos, false);

        setErrorMessage(null); // Сбрасываем сообщение об ошибке
      }
    }
  };

  const handleButtonClick = () => {
    validateFields();
  };

  const handleSelectTimezoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedKey = e.target.value; // Получаем выбранное значение (value) из select
    const selectedValue = (_zones as any)[selectedKey]; // Получаем соответствующее ему значение из _zones
  
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      timezone: selectedValue, // Устанавливаем значение для timezone
      timezoneloc: selectedKey // Устанавливаем ключ для timezoneloc
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedKey = e.target.value; // Получаем выбранное значение (value) из select
    const name = e.target.name;
  
    setFormValues((prevFormValues: Settings)  => {
      const updatedValues: Settings = { ...prevFormValues };
      (updatedValues as any)[name] = +selectedKey;
      return updatedValues;
    });
  };
 
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    const [parentName, childName, idx] = name.split('.'); // Разделяем имя элемента на родительское и дочернее
  
    setFormValues((prevFormValues: Settings)  => {
      const updatedValues: Settings = { ...prevFormValues }; // Явное уточнение типа
     
      if (childName) {
        if(parentName === "timer"){
          if(idx !== undefined){
            // Если есть дочерний элемент, обновляем состояние с учетом вложенности
            const timerIdx = parseInt(idx);
            const timerArray = updatedValues[parentName] || [];
            const timerItem = timerArray[timerIdx] || {};
            const updatedTimerItem = {
              ...timerItem,
              [childName]: type === "checkbox" ? (checked ? 1 : 0) : (type === "text" ? value : Number(value))
            };
            const updatedTimerArray = [...timerArray.slice(0, timerIdx), updatedTimerItem, ...timerArray.slice(timerIdx + 1)];
            updatedValues[parentName] = updatedTimerArray;
          }
        }
        else{
          // Если есть дочерний элемент, обновляем состояние с учетом вложенности
          (updatedValues as any)[parentName] = {
            ...((updatedValues as any)[parentName] || {}),
            [childName]: type === "checkbox" ? (checked ? 1 : 0) : (type === "text" ? value : Number(value)),
          };
        }
      } else {
        // Если нет дочернего элемента, обновляем состояние без учета вложенности
        if (type === "checkbox") {
        // Обработка для checkbox
          (updatedValues as any)[name] = checked ? 1 : 0;
        } else if (type === "number") {
          // Обработка для числового поля
          (updatedValues as any)[name] = Number(value);
        } else {
          // Обработка для текстового поля
          (updatedValues as any)[name] = value;
        }
      }
                
      return updatedValues;
    });
  };

  // const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = e.target;
  //   setFormValues((prevFormValues) => ({
  //     ...prevFormValues,
  //     [name]: checked ? 1 : 0,
  //   }));
  // };

  useEffect(() => {
    
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        description: settings?.description || "",
        mqtt_broker_uri: settings?.mqtt_broker_uri || "",
        webPassw: settings?.webPassw || "",
        masterRFID: settings?.masterRFID || "",
        timezone: settings?.timezone || "",
        timezoneloc: settings?.timezoneloc || "",
        irCarrierFreq: settings?.irCarrierFreq || 0,
        irCarrierDC: settings?.irCarrierDC || 0,
        dateFormat: settings?.dateFormat || 0,
        bcklightLCD: settings?.bcklightLCD || 0,
        auxSensType: settings?.auxSensType || 0,
        altRfidButCh: settings?.altRfidButCh !== undefined ? settings.altRfidButCh : -1,
        ble1: settings?.ble1 || "",
        ble2: settings?.ble2 || "",
        ble1Ch: settings?.ble1Ch !== undefined ? settings.ble1Ch : -1,
        ble2Ch: settings?.ble2Ch !== undefined ? settings.ble2Ch : -1,
        thermostat: settings?.thermostat ? {
          defrostLo: settings?.thermostat.defrostLo || 0,
          defrostHi: settings?.thermostat.defrostHi || 0,
          tempWinLo: settings?.thermostat.tempWinLo || 0,
          tempWinHi: settings?.thermostat.tempWinHi || 0,
          tempSumLo: settings?.thermostat.tempSumLo || 0,
          tempSumHi: settings?.thermostat.tempSumHi || 0,
          consumption: settings?.thermostat.consumption || 0,
          tariff: settings?.thermostat.tariff || 0,
          autoSeason: settings?.thermostat.autoSeason || 0,
          summerDate: settings?.thermostat.summerDate || "",
          winterDate: settings?.thermostat.winterDate || "",
          timeoutPIR: settings?.thermostat.timeoutPIR || 0,
          tempHysteresis: settings?.thermostat.tempHysteresis || 0,
          autoShutOffEna: settings?.thermostat.autoShutOffEna || 0,
          defrostEna: settings?.thermostat.defrostEna || 0,
          openWindowCtrl: settings?.thermostat.openWindowCtrl || 0
        } : {
          defrostLo: 0,
          defrostHi: 0,
          tempWinLo: 0,
          tempWinHi: 0,
          tempSumLo: 0,
          tempSumHi: 0,
          consumption: 0,
          tariff: 0,
          autoSeason: 0,
          summerDate: "",
          winterDate: "",
          timeoutPIR: 0,
          tempHysteresis: 0,
          autoShutOffEna: 0,
          defrostEna: 0,
          openWindowCtrl: 0
        },
        timer: settings?.timer ? settings?.timer.map((item: any) => ({
          channel: item.channel || 0,
          consumption: item.consumption || 0,
          tariff: item.tariff || 0,
          timeoutPIR: item.timeoutPIR || 0,
          lockEna: item.lockEna || 0,
          lockPol: item.lockPol || 0,
          lockHold: item.lockHold || 0,
          rfid1: item.rfid1 || "",
          rfidDescription1: item.rfidDescription1 || "",
          rfid2: item.rfid2 || "",
          rfidDescription2: item.rfidDescription2 || ""
        })) : []
      }));
    
  }, [settings]);

  return (
    <div>
      <legend>Settings</legend>
      <form ref={formRef}>
      <table className="table">
        <tbody>
        <tr><th className="cell-header">Common</th><th className="cell-header"></th></tr>
          <tr><td className="cell">Description</td><td><input className="cell" name="description" type="text" value={formValues.description} onChange={handleInputChange} required/></td></tr>
          <tr><td className="cell">MQTT broker URI</td><td><input className="cell" name="mqtt_broker_uri" type="text" value={formValues.mqtt_broker_uri} onChange={handleInputChange} required/></td></tr>
          <tr><td className="cell">Location</td>
            <td><select className="cell" name="timezoneloc" value={formValues.timezoneloc || ""} onChange={handleSelectTimezoneChange} required>
              <option value="" disabled hidden>Select timezone</option>
              {Object.entries(_zones).map(([key, value]) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
            </select></td>
          </tr>
          <tr><td className="cell">LCD date format (dd.mm/mm.dd)</td><td><input className="cell" name="dateFormat" type="checkbox" checked= {formValues.dateFormat === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">LCD backlight (always on/auto off on timeout)</td><td><input className="cell" name="bcklightLCD" type="checkbox" checked= {formValues.bcklightLCD === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Shortcut button function:</td><td>
            <select className="cell" name="altRfidButCh" value={formValues.altRfidButCh} onChange={handleSelectChange} required>
              <option value={-1} disabled hidden>Select function...</option>
              <option value={2}>Disabled</option>
              <option value={0}>Ch A control</option>
              <option value={1}>Ch B control</option>
            </select>
          </td></tr>
          <tr><td className="cell">BLE tag #1 function:</td><td>
            <select className="cell" name="ble1Ch" value={formValues.ble1Ch} onChange={handleSelectChange} required>
              <option value={-1} disabled hidden>Select function...</option>
              <option value={0}>Disabled</option>
              <option value={1}>Ch T control (in AUTO mode, PIR sensor enabled)</option>
              <option value={2}>Ch T control (in AUTO mode, PIR sensor disabled)</option>
              <option value={3}>Ch A control (for lock mode control and on holding tag enabled)</option>
              <option value={4}>Ch B control (for lock mode control and on holding tag enabled)</option>
            </select>
          </td></tr>
          <tr><td className="cell">BLE tag #2 function:</td><td>
            <select className="cell" name="ble2Ch" value={formValues.ble2Ch} onChange={handleSelectChange} required>
              <option value={-1} disabled hidden>Select function...</option>
              <option value={0}>Disabled</option>
              <option value={1}>Ch T control (in AUTO mode, PIR sensor enabled)</option>
              <option value={2}>Ch T control (in AUTO mode, PIR sensor disabled)</option>
              <option value={3}>Ch A control (for lock mode control and on holding tag enabled)</option>
              <option value={4}>Ch B control (for lock mode control and on holding tag enabled)</option>
            </select>
          </td></tr>    

          <tr><th className="cell-header">Thermostat</th><th className="cell-header"></th></tr>
          <tr><td className="cell">Defrost low temperature threshold</td><td><input className="cell" name="thermostat.defrostLo" type="number" value={formValues.thermostat.defrostLo.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Defrost high temperature threshold</td><td><input className="cell" name="thermostat.defrostHi" type="number" value={formValues.thermostat.defrostHi.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Low temperature threshold for winter</td><td><input className="cell"  name="thermostat.tempWinLo" type="number" value={formValues.thermostat.tempWinLo.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">High temperature threshold for winter</td><td><input className="cell" name="thermostat.tempWinHi" type="number" value={formValues.thermostat.tempWinHi.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Low temperature threshold for summer</td><td><input className="cell" name="thermostat.tempSumLo" type="number" value={formValues.thermostat.tempSumLo.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">High temperature threshold for summer</td><td><input className="cell" name="thermostat.tempSumHi" type="number" value={formValues.thermostat.tempSumHi.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">AC power consumptiom, kWh</td><td><input className="cell" name="thermostat.consumption" type="number" value={formValues.thermostat.consumption.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Tariff</td><td><input className="cell"name="thermostat.tariff" type="number" value={formValues.thermostat.tariff.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Automatic switch season date</td><td><input className="cell" type="checkbox" name="thermostat.autoSeason" checked= {formValues.thermostat.autoSeason === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Start summer season date (dd.mm)</td><td><input className="cell" type="text" name="thermostat.summerDate" value={formValues.thermostat.summerDate} onChange={handleInputChange} required pattern="^((?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))$|^(?:29(\.)(?:0?2)\3)$|^(0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4$"/></td></tr>
          <tr><td className="cell">Start winter season date (dd.mm)</td><td><input className="cell" type="text" name="thermostat.winterDate" value={formValues.thermostat.winterDate} onChange={handleInputChange} required pattern="^((?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))$|^(?:29(\.)(?:0?2)\3)$|^(0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4$"/></td></tr>
          <tr><td className="cell">Time to shutoff after last movement, minutes</td><td><input className="cell" type="number" name="thermostat.timeoutPIR" value={formValues.thermostat.timeoutPIR} onChange={handleInputChange} required pattern="^[0-9]+"/></td></tr>
          <tr><td className="cell">Temperature thresholds hysteresis</td><td><input className="cell" type="number" name="thermostat.tempHysteresis" value={formValues.thermostat.tempHysteresis.toFixed(2)} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Auto shutoff enable</td><td><input className="cell" type="checkbox" name="thermostat.autoShutOffEna" checked= {formValues.thermostat.autoShutOffEna === 1}  onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Defrost enable</td><td><input className="cell" type="checkbox" name="thermostat.defrostEna" checked= {formValues.thermostat.defrostEna === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Open door/window sensor enable</td><td><input className="cell" type="checkbox" name="thermostat.openWindowCtrl" checked= {formValues.thermostat.openWindowCtrl === 1} onChange={handleInputChange}/></td></tr>

          <tr><th className="cell-header">Timer A</th><th className="cell-header"></th></tr>
          <tr><td className="cell">AC power consumptiom, kWh</td><td><input className="cell" type="number" name="timer.consumption.0" value={formValues.timer[0]?.consumption.toFixed(2) || 0} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Tariff</td><td><input className="cell" type="number" name="timer.tariff.0" value={formValues.timer[0]?.tariff.toFixed(2) || 0} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Time to shutoff after last movement, minutes</td><td><input className="cell" type="number" name="timer.timeoutPIR.0" value={formValues.timer[0]?.timeoutPIR || 0} onChange={handleInputChange} required pattern="^[0-9]+"/></td></tr>
          <tr><td className="cell">RFID tag control settings</td><td></td></tr>
          <tr><td className="cell">Manual channel control / lock mode control</td><td><input className="cell" type="checkbox" name="timer.lockEna.0" checked= {formValues.timer[0]?.lockEna === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Lock mode active state polarity (low/hi)</td><td><input className="cell" type="checkbox"  name="timer.lockPol.0" checked= {formValues.timer[0]?.lockPol === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Lock mode active state timeout (30sec / on holding tag)</td><td><input className="cell" type="checkbox"  name="timer.lockHold.0" checked= {formValues.timer[0]?.lockHold === 1} onChange={handleInputChange}/></td></tr>

          <tr><th className="cell-header" >Timer B</th><th className="cell-header"></th></tr>
          <tr><td className="cell">AC power consumptiom, kWh</td><td><input className="cell" type="number" name="timer.consumption.1" value={formValues.timer[1]?.consumption.toFixed(2) || 0} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Tariff</td><td><input className="cell" type="number" name="timer.tariff.1" value={formValues.timer[1]?.tariff.toFixed(2) || 0} onChange={handleInputChange} required pattern="^[0-9]*[.]?[0-9]+$"/></td></tr>
          <tr><td className="cell">Time to shutoff after last movement, minutes</td><td><input className="cell" type="number" name="timer.timeoutPIR.1" value={formValues.timer[1]?.timeoutPIR || 0} onChange={handleInputChange} required pattern="^[0-9]+"/></td></tr>
          <tr><td className="cell">RFID tag control settings</td><td></td></tr>
          <tr><td className="cell">Manual channel control / lock mode control</td><td><input className="cell" type="checkbox" name="timer.lockEna.1" checked= {formValues.timer[1]?.lockEna === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Lock mode active state polarity (low/hi)</td><td><input className="cell" type="checkbox"  name="timer.lockPol.1" checked= {formValues.timer[1]?.lockPol === 1} onChange={handleInputChange}/></td></tr>
          <tr><td className="cell">Lock mode active state timeout (30sec / on holding tag)</td><td><input className="cell" type="checkbox"  name="timer.lockHold.1" checked= {formValues.timer[1]?.lockHold === 1} onChange={handleInputChange}/></td></tr>

          <tr><td className="cell">IR carrier frequency, Hz (30000-60000, 38000 default)</td><td><input className="cell"  name="irCarrierFreq" type="number" value={formValues.irCarrierFreq} onChange={handleInputChange}  required pattern="^[3-5][\d]{4}|60000$"/></td></tr>
          <tr><td className="cell">IR carrier duty cycle, % (1-50, 50 default)</td><td><input className="cell"  name="irCarrierDC" type="number" value={formValues.irCarrierDC} onChange={handleInputChange}  required pattern="^[1-4]?[1-9]|[1-5]0$"/></td></tr>
          <tr><td className="cell">Auxiliary sensor type (DHT11/DHT22)</td><td><input className="cell" type="checkbox" name="auxSensType" checked= {formValues.auxSensType === 1} onChange={handleInputChange}/></td></tr>   
          </tbody>
      </table>
      </form>
      <button type="button" className="save-button" onClick={handleButtonClick} disabled={settings != undefined ? false : true}>Save</button>
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
};

export default SettingsPage;