import React from "react";
import "./style.css";

const Modal = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => {
return (
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal-window">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </>
  );
};

export default Modal;
