import DashboardPageLayout from "../pages/dashboard/DashboardPageLayout";
import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import MonitorPage from "../pages/dashboard/MonitorPage";
import DashboardIndex from "../pages/dashboard/DashboardIndex";
import LogoutPage from "../pages/logout/LogoutPage";
import SchedulesPage from "../pages/dashboard/SchedulesPage";
import ProgramsPage from "../pages/dashboard/ProgramsPage";
import SettingsPage from "../pages/dashboard/SettingsPage";
import SystemPage from "../pages/dashboard/SystemPage";
import { DashboardOutlined, CloudOutlined, DeviceThermostatOutlined,
  ScheduleOutlined, EditCalendarOutlined, SettingsOutlined, ConstructionOutlined, 
  LogoutOutlined} from '@mui/icons-material';
// import CalendarMonthOutlined  from '@mui/icons-material/CalendarMonthOutlined';

import DevicesPage from "../pages/devices/DevicesPage";
import MqttService from "../services/mqtt/mqttService";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home"
  },
  {
    path: "/devices",
    element: <DevicesPage />,
    state: "devices",
    sidebarProps: {
      displayText: "Devices",
      icon: <CloudOutlined />
    }
  },
  {
    path: "/dashboard",
    element: <DashboardPageLayout />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardOutlined />
    },
    child: [
      {
        index: true,
        element: <DashboardIndex />,
        state: "dashboard.index"
      },
      {
        path: "/dashboard/monitor",
        element: <MonitorPage mqttService={MqttService.getInstance()} />,
        state: "dashboard.monitor",
        sidebarProps: {
          displayText: "Monitor",
          icon: <DeviceThermostatOutlined />
        },
      },
      {
        path: "/dashboard/programs",
        element: <ProgramsPage mqttService={MqttService.getInstance() }/>,
        state: "dashboard.programs",
        sidebarProps: {
          displayText: "Programs",
          icon: <ScheduleOutlined />
        }
      },
      {
        path: "/dashboard/schedules",
        element: <SchedulesPage mqttService={MqttService.getInstance()}/>,
        state: "dashboard.schedules",
        sidebarProps: {
          displayText: "Schedules",
          icon: <EditCalendarOutlined />
        }
      },
      {
        path: "/dashboard/settings",
        element: <SettingsPage mqttService={MqttService.getInstance()}/>,
        state: "dashboard.settings",
        sidebarProps: {
          displayText: "Settings",
          icon: <SettingsOutlined />
        }
      },
      {
        path: "/dashboard/system",
        element: <SystemPage mqttService={MqttService.getInstance()}/>,
        state: "dashboard.system",
        sidebarProps: {
          displayText: "System tools",
          icon: <ConstructionOutlined />
        }
      }
    ]
  },
  {
    path: "/logout",
    element: <LogoutPage />,
    state: "logout",
    sidebarProps: {
      displayText: "Logout",
      icon: <LogoutOutlined />
    }
  }
];

export default appRoutes;